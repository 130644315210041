import * as React from "react";
import "./RoadmapSlides.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import FeatureCard from "../FeatureCard/FeatureCard";
import axios from "axios";

const RoadmapSlides = () => {
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      setLoading(true);
      axios
        .get("https://api-landing.qavant.com/roadmap/list")
        .then((res) => setData(res.data.roadmaps))
        .catch((error) => console.log(error))
        .finally(() => setLoading(false));
    }
    return () => {
      mounted = false;
    };
  }, []);

  if (loading) {
    return (
      <div className="roadmap-loader-container">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }

  return (
    <section className="roadmap">
      <div className="roadmap-content">
        <h1>Roadmap</h1>
        {data &&
          data.map((data) => {
            return (
              <div key={data.year} className="year-container">
                <hr className="divider" />
                <p className="year-title">{data.year}</p>
                <div className="swiper-wrapper-container">
                  <Swiper slidesPerView="auto" spaceBetween={20}>
                    {data.features.map((feature) => {
                      return (
                        <SwiperSlide key={feature.id}>
                          <FeatureCard feature={feature} />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </div>
              </div>
            );
          })}
      </div>
    </section>
  );
};

export default RoadmapSlides;
